<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body">
			<div class="center" v-loading="isLoading">
				<div class="page_links">
					当前位置：
					<el-breadcrumb separator-class="el-icon-arrow-right">
						<el-breadcrumb-item :to="{ path: '/uc_goods' }">我的商品</el-breadcrumb-item>
						<el-breadcrumb-item>关联产品</el-breadcrumb-item>
					</el-breadcrumb>
				</div>
				<div class="connect_main">
					<p class="fb">关联产品规格</p>
					<div class="goods_info">
						<div class="img_box"><img class="coverimg" :src="productData.img" /></div>
						<div class="con_box">
							<p class="name">{{productData.name}}</p>
							<p class="id">商品ID：{{productData.goods_id}}</p>
						</div>
						<el-button class="search_btn" size="mini" plain @click="is_tongkuan = true">搜同款</el-button>
					</div>
					<div class="table_wrap">
						<el-table :data="links_list">
							<el-table-column label="编号" width="100" type="index"></el-table-column>
							<el-table-column label="商品链接" prop="scope" class="c_price">
								<template slot-scope="scope">
									<el-input v-model="scope.row.links" :readonly="scope.row.isUse"></el-input>
								</template>
							</el-table-column>
							<el-table-column label="操作" prop="scope" width="150">
								<template slot-scope="scope">
									<p class="get_config" @click="getConfig(scope.row.links,scope.$index)">获取规格</p>
								</template>
							</el-table-column>
							<el-table-column label="" prop="scope" width="150">
								<template slot-scope="scope">
									<p class="get_config" @click="deleteConfig(scope.row.product_id,scope.$index)">删除</p>
								</template>
							</el-table-column>
						</el-table>
					</div>
					<div class="add_line">
						<p class="add_btn" @click="addLinks()">+添加链接</p>
						<p class="clear" @click="clearSelConfig()"><i class="iconfont icon-delete"></i>清空匹配的规格</p>
					</div>
					<div class="config_wrap">
						<div class="config_table">
							<el-table :data="dd_sku_list" height="250">
								<el-table-column label="当前关联产品属性" prop="scope" width="">
									<template slot-scope="scope">
										<p class="ct_name">{{scope.row.spec_value}}(￥{{scope.row.sku_price}})</p>
									</template>
								</el-table-column>
								<el-table-column label="卖价" prop="scope" width="150">
									<template slot-scope="scope">
										<p class="ct_price">￥{{scope.row.price}}</p>
									</template>
								</el-table-column>
								<el-table-column label="抖店产品属性" prop="scope" width="">
									<template slot-scope="scope">
										<p class="ct_name">{{scope.row.dd_spec_value}}</p>
									</template>
								</el-table-column>
								<el-table-column label="更改关联产品" prop="scope" width="400">
									<template slot-scope="scope">
										<el-select v-model="scope.row.sel_product_sku_id" placeholder="请选择" @visible-change="selectChange" clearable>
											<el-option
												v-for="(item,index) in current_sku" :key="index"
												:label="item.show_spec"
												:value="item.product_sku_id">
											</el-option>
										</el-select>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<div class="btn_wrap">
							<p class="btn" @click="configSubmit()">确定</p>
						</div>
					</div>
				</div>
				<div :class="is_tongkuan?'tongkuan_mask active' : 'tongkuan_mask'" @click.self="is_tongkuan = false">
					<div class="tongkuan_main" v-loading="searchLoading">
						<i class="iconfont icon-guanbi" @click.self="is_tongkuan = false"></i>
						<p class="title">搜同款</p>
						<p class="title2">精选供应链平台</p>
						<div class="search_wrap">
							<el-input v-model="search_input" placeholder="请输入..." class="search_input">
								<p slot="suffix" class="icons" @click="bindSearchSimilar"><i class="iconfont icon-sousuo"></i></p>
							</el-input>
						</div>
						<div class="content_wrap" v-if="similarData.total>0">
							<div class="tk_content auto_scrollbar">
								<div class="product" v-for="(item,index) in similarData.data" :key="index">
									<div class="img_box"><img class="coverimg" :src="item.img" /></div>
									<p class="title">{{item.name}}</p>
									<div class="price_line">
										<p class="price">￥{{item.discount_price}}</p>
										<el-button size="mini" type="primary" @click="selectProduct(item.link)">选择商品</el-button>
									</div>
								</div>
							</div>
							<div class="turn_page">
								<el-pagination
								      :page-size="similarData.per_page"
								      layout="total,prev, pager, next, jumper"
								      prev-text="上一页"
								      next-text="下一页"
								      :total="similarData.total"
								      hide-on-single-page
								      :current-page="similarData.current_page"
								      @current-change="currentChange">
								</el-pagination>
							</div>
						</div>
						<el-empty v-else description="暂无数据"></el-empty>
					</div>
				</div>
			</div>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "goodsConnect",
	data() {
		return {
			isLoading:true,
			item_id:"",
			productData:"",
			dd_sku_list:[],
			links_list:[{links:"",isUse:false,product_id:""}],
			isSubmit:false,
			current_sku:[],
			is_tongkuan:false,
			search_input:"",
			isSearch:false,
			searchLoading:false,
			similarData:"",
		}
	},
	components:{
		myHead,myFoot
	},
	created(){
		this.$nextTick(function () {
			this.item_id = this.$route.query.id
			this.init()
		})
		
	},
	methods: {
		...mapActions({
			GetGoodsRelativeInfoData:"ucenter/GetGoodsRelativeInfoData",
			GetGoodsConfigData:"ucenter/GetGoodsConfigData",
			doSubmitRelative:"ucenter/doSubmitRelative",
			getSimilarGoodsData:"ucenter/getSimilarGoodsData",
			opCheckoutSkuRelative:"ucenter/opCheckoutSkuRelative"
		}),
		init(){//获取商品信息
			this.GetGoodsRelativeInfoData({data:{id:this.item_id},success:(res)=>{
				if(res.code===200){
					// console.log('relative info',res)
					this.isLoading = false
					this.productData = JSON.parse(JSON.stringify(res.data))
					let skuList = JSON.parse(JSON.stringify(res.data.dd_sku))
					for(let i in skuList){
						skuList[i].sku = []
						skuList[i].sel_product_sku_id = ""
					}
					this.dd_sku_list = skuList
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		addLinks(){//添加链接
			for(let i in this.links_list){
				if(!this.links_list[i].links){
					this.$message({message: '请勿重复添加新链接！',type: 'error',});
					return false
				}
			}
			this.links_list.push({links:"",isUse:false,product_id:""})
		},
		getConfig(url,i){//获取规格
			let skuList = this.dd_sku_list
			this.isLoading = true
			if(!url){
				this.$message({message: '请输入商品链接！',type: 'error',});
				return false
			}
			if(this.links_list[i].isUse){
				this.$message({message: '该链接已关联~',type: 'warning',});
				return false
			}
			if(this.links_list.length>1){
				for(let i = 0 ; i < this.links_list.length - 1 ; i++){
					if(this.links_list[i].links==url){
						this.$message({message: '请勿输入相同链接！',type: 'warning',});
						return false
					}
				}
			}
			this.GetGoodsConfigData({data:{url},success:(res)=>{
				if(res.code===200){
					// console.log('get config success',res)
					this.links_list[i].isUse = true
					this.links_list[i].product_id = res.data.product_id
					if(this.links_list.length>1){
						this.current_sku = this.current_sku.concat(res.data.sku)
					}else{
						this.current_sku = res.data.sku
					}
					for(let a in skuList){
						for(let b in this.current_sku){
							if(skuList[a].dd_spec_value == this.current_sku[b].spec_value){
								skuList[a].sel_product_sku_id = this.current_sku[b].product_sku_id
								break
							}
						}
					}
					for(let c in this.current_sku){
						this.current_sku[c].show_spec = ''
						this.current_sku[c].show_spec = this.current_sku[c].spec_value + '(￥' + this.current_sku[c].price + ')'
					}
					this.dd_sku_list = skuList
					this.isLoading = false
					this.$message({message: res.msg,type: 'success',});
				} else {
					this.$message({message: res.msg,type: 'error',});
					this.isLoading = false
				}
			}})
		},
		configSubmit(){//关联商品规格
			let that = this
			let skuList = this.dd_sku_list
			let current_sku = this.current_sku
			this.isLoading = true
			let param = []
			for(let i in skuList){
				if(skuList[i].sel_product_sku_id){
					
					let product_sku_idx = current_sku.findIndex(val => val.product_sku_id == skuList[i].sel_product_sku_id)
					
					param.push({"dd_sku_id":skuList[i].dd_sku_id,"product_sku_id":skuList[i].sel_product_sku_id,'goods_no':current_sku[product_sku_idx].goods_no})
				}
			}
			console.log(param)
			// return
			if(!this.isSubmit){
				
				that.opCheckoutSkuRelative({data:{sku_id:JSON.stringify(param)},success:(res)=>{
					console.log(res)
					if(res.code===200){
						let order_product_count = res.data.order_product_count
						let change_total_price = res.data.change_total_price
						const h = that.$createElement;
						that.$msgbox({
							title: '消息',
							message: h('p', null, [
								h('span', null, '此操作将同步关联所有对应商品sku的未支付、已支付，待发货的订单，总计'),
								h('i', { style: 'color: #ff0000' }, order_product_count+'单'),
								h('span', null, '，合计'+(change_total_price>0?'退':'补 ')),
								h('i', { style: 'color: #ff0000' }, (change_total_price>0?change_total_price:-change_total_price)+'元'),
								h('span', null, '，是否确认操作？'),
							]),
							showCancelButton: true,
							confirmButtonText: '确定',
							cancelButtonText: '取消',
						}).then(action => {
							that.isSubmit = true
							that.doSubmitRelative({data:{sku_id:JSON.stringify(param)},success:(res)=>{
								if(res.code===200){
									// console.log('success',res)
									that.$message({message: '关联成功',type: 'success',});
									that.isSubmit = false
									that.isLoading = false
									location.reload();
								} else {
									that.$message({message: res.msg,type: 'error',});
									that.isSubmit = false
									that.isLoading = false
								}
							}})
						}).catch(e => {
							that.isSubmit = false
							that.isLoading = false
						});
					} else {
						that.$message({message: res.msg,type: 'error',});
						that.isSubmit = false
						that.isLoading = false
					}
				}})
				
				
			}
		},
		clearSelConfig(){//清除选中的关联规格
			let skuList = this.dd_sku_list
			for(let i in skuList){
				skuList[i].sel_product_sku_id = ""
			}
			this.dd_sku_list = skuList
		},
		deleteConfig(id,i){
			this.links_list.splice(i,1)
			if(this.links_list.length==0){
				this.links_list.push({links:"",isUse:false,product_id:""})
			}
			this.current_sku = this.current_sku.filter(item => item.product_id != id)
			// console.log('del',id,this.current_sku,this.dd_sku_list)
		},
		selectChange(value){//检测无规格
			if(value && this.current_sku.length==0){
				this.$message({message: '暂无数据，请添加链接并获取规格！',type: 'warning',});
			}
		},
		bindSearchSimilar(){//搜索同款商品
			if(!this.search_input){
				this.$message({message: '请输入搜索内容！',type: 'warning',});
				return false
			}
			if(!this.isSearch){
				this.isSearch = true
				this.searchLoading = true
				this.getSimilarGoodsData({data:{name:this.search_input},success:(res)=>{
					if(res.code===200){
						// console.log('success',res)
						this.similarData = res.data
						this.searchLoading = false
					} else {
						this.$message({message: res.msg,type: 'error',});
						this.searchLoading = false
					}
					this.isSearch = false
				}})
			}
		},
		currentChange(val){//分页
			this.similarData.current_page = val
			this.bindSearchSimilar()
		},
		selectProduct(url){//选中同款商品
			let list = this.links_list
			if(!list[list.length - 1].isUse){
				list[list.length - 1].links = url
			}else{
				this.links_list.push({links:url,isUse:false,product_id:""})
			}
			this.$message({message: '链接复制成功！',type: 'success',});
			setTimeout(()=>{this.is_tongkuan = false},500)
		},
	}
}
</script>

<style scoped>
.tongkuan_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.tongkuan_mask.active{visibility: visible;opacity: 1;}
.tongkuan_mask .tongkuan_main{width: 1200px;height: 800px;box-sizing: border-box;padding: 20px 30px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;display: flex;flex-direction: column;}
.tongkuan_mask.active .tongkuan_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.tongkuan_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.tongkuan_main .title{font-size: 20px;line-height: 1;}
.tongkuan_main .title2{margin-top: 10px;font-size: 24px;text-align: center;line-height: 1;}
.tongkuan_main .search_wrap{margin: 10px 50px;}
.tongkuan_main .content_wrap{display: flex;flex-direction: column;flex: 1;}
.tongkuan_main .tk_content{flex: 1;height: 0;overflow: hidden auto;display: flex;flex-wrap: wrap;align-items: flex-start;padding-bottom: 10px;padding-left: 4px;}
.tk_content .product{width: 210px;height: auto;margin: 18px 18px 0 0;border-radius: 6px;box-shadow: 0 0 5px 0 #eee;overflow: hidden;}
.tk_content .product:nth-child(5n){margin-right: 0;}
.tk_content .product .img_box{width: 100%;height: 210px;overflow: hidden;}
.tk_content .product .title{margin: 10px 10px 0;font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;cursor: pointer;}
.tk_content .product .title:hover{color: var(--red);text-decoration: underline;}
.tk_content .product .price_line{padding: 10px;display: flex;justify-content: space-between;align-items: center;}
.tk_content .product .price_line .price{font-size: 14px;color: var(--red);}
.turn_page{display: flex;justify-content: flex-end;margin-top: 20px;}
.connect_main{margin-top: 30px;width: 100%;height: auto;box-sizing: border-box;padding: 30px 40px;background: #fff;line-height: 1;}
.goods_info{margin-top: 26px;display: flex;position: relative;}
.goods_info .img_box{width: 48px;height: 48px;background: #eee;border-radius: 6px;overflow: hidden;}
.goods_info .con_box{flex: 1;width: 0;margin-left: 10px;font-size: 12px;}
.goods_info .con_box .name{line-height: 16px;}
.goods_info .con_box .id{color: #999999;margin-top: 8px;}
.goods_info .search_btn{position: absolute;right: 50px;top: 50%;transform: translateY(-50%);z-index: 22;}
.goods_info .search_btn:hover ,.goods_info .search_btn:focus{border-color: var(--red);color: var(--red);}
.table_wrap{margin-top: 30px;font-size: 12px;}
.get_config{color: var(--red);cursor: pointer;}
.get_config:hover{text-decoration: underline;}
.table_wrap .pingtai{width: 80px;margin: 0 auto;text-align: left;box-sizing: border-box;padding-left: 20px;}
.add_line{display: flex;justify-content: space-between;align-items: flex-end;margin: 30px 0 20px;}
.add_line .add_btn{width: 102px;height: 35px;box-sizing: border-box;border: 1px solid var(--red);border-radius: 4px;cursor: pointer;display: flex;justify-content: center;align-items: center;font-size: 14px;color: var(--red);}
.add_line .clear{font-size: 14px;color: #999999;display: flex;align-items: center;cursor: pointer;}
.add_line .clear .iconfont{font-size: 14px;margin-right: 8px}
.config_wrap .title_wrap{width: 100%;height: 50px;background: #f5f5f5;box-sizing: border-box;border: 1px solid #eee;display: flex;align-items: center;font-size: 14px;color: #999}
.config_wrap .title_wrap .t1{width: 320px;margin: 0 16px;}
.config_wrap .title_wrap .t2{width: 80px;text-align: center;}
.config_wrap .title_wrap .t3{width: 120px;margin: 0 30px 0 20px;text-align: center;}
.config_wrap .title_wrap .t4{flex: 1;width: 0;}
.config_wrap .content_wrap{margin-top: 20px;padding-bottom: 40px;border-bottom: 1px solid #eee;height: 220px;overflow-y: auto;overflow-x: hidden;font-size: 14px;}
.content_wrap .content_line{display: flex;align-items: flex-start;margin-top: 26px;}
.content_wrap .content_line:first-child{margin-top: 0;}
.content_line .b1{width: 320px;margin: 0 16px;line-height: 20px;box-sizing: border-box;padding-right: 50px;}
.content_line .b2{width: 80px;text-align: center;}
.content_line .b3{width: 120px;margin: 0 30px 0 20px;display: flex;justify-content: center;align-items: center;}
.content_line .b4{flex: 1;width: 0;display: flex;}
.content_line .b3 .t{margin-right: 10px;}
.btn_wrap{margin-top: 20px;display: flex;justify-content: flex-end;}
.btn_wrap .btn{width: 90px;height: 32px;border-radius: 4px;display: flex;justify-content: center;align-items: center;margin-left: 20px;cursor: pointer;font-size: 12px;box-sizing: border-box;}
.btn_wrap .btn:nth-child(1){background: var(--red);color: #fff;}
.btn_wrap .btn:nth-child(2){border: 1px solid #ddd;color: #333333;}

.tongkuan_main .el-button--primary ,.tongkuan_main .el-button--primary:hover{background-color: var(--red) !important;border-color: var(--red) !important;}
</style>
<style>

.page_links{line-height: 1;font-size: 14px;color: #999999;display: flex;margin-top: 25px;}
.page_links .el-breadcrumb__inner{font-weight: bold !important;color: #333 !important;}
.page_links .el-breadcrumb__inner.is-link{font-weight: initial !important;color: #999 !important;}
.page_links .el-breadcrumb__inner.is-link:hover{color: #f0423f !important;text-decoration: underline;}

.tongkuan_main .search_wrap .el-input__inner{border-radius: 6px;font-size: 16px;}
.tongkuan_main .search_wrap .el-input__suffix{right: 1px;display: flex;top: 1px;border-radius: 0 6px 6px 0;height: 38px;overflow: hidden;border-left: 1px solid #ddd;}
.tongkuan_main .search_wrap .el-input__suffix .el-input__suffix-inner{width: 80px;height: 38px;}
.tongkuan_main .search_wrap .el-input__suffix .el-input__suffix-inner .icons{width: 100%;height: 100%;display: flex;justify-content: center;align-items: center;background: #f5f5f5;cursor: pointer;}
.tongkuan_main .search_wrap .el-input__suffix .el-input__suffix-inner .icons .iconfont{font-size: 16px;color: #999;}
.table_wrap .el-table::before{display: none;}
.table_wrap .el-table .el-table_1_column_2 .cell .num{color: #999;}
.table_wrap .el-table th.el-table__cell{background: #fff6f7;border-bottom: none !important;color: #666666;padding: 12px 0;}
.table_wrap .el-table .el-table__cell{padding: 24px 0;}
.table_wrap .el-table .cell{padding: 0 20px;text-align: center;font-size: 12px;color: #333;}
.table_wrap .el-table .el-table_1_column_1 .cell{text-align: center;}
.table_wrap .el-table .el-table_1_column_2 .cell{padding: 0 10px;}
.table_wrap .el-table th.el-table_1_column_2 .cell{text-align: left;}
.table_wrap .el-table td.el-table__cell, .table_wrap .el-table th.el-table__cell.is-leaf{border-bottom: none;}

.config_table .el-table__header-wrapper{border: 1px solid #eee !important;box-sizing: border-box;}
.config_table .el-table th.el-table__cell{background: #f5f5f5;border: none !important;color: #999;padding: 13.5px 0;}
.config_table .el-table td.el-table__cell{border-bottom: none !important;}
.config_table .el-table .cell{padding: 0 16px !important;}
.config_table .el-table td.el-table__cell div{width: 100%;}
.config_table .el-table__body-wrapper::-webkit-scrollbar{width:4px;}
.config_table .el-table__body-wrapper::-webkit-scrollbar-thumb{background: #DDDDDD;border-radius: 4px;}
.config_table .el-table__body-wrapper::-webkit-scrollbar-track{background: #F2F2F2;border-radius: 4px;}



.content_line .b4 .el-select{width: 220px;margin-right: 10px;}
.el-input-number__decrease:hover, .el-input-number__increase:hover ,.el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path ,.el-select-dropdown__item.selected{color: var(--red);}
.input_number .el-input__inner:hover ,.el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) ,.el-input__inner:focus ,.el-cascader .el-input .el-input__inner:focus, .el-cascader .el-input.is-focus .el-input__inner ,.el-select .el-input.is-focus .el-input__inner ,.el-select .el-input__inner:focus{border-color: var(--red);}
</style>